
import Historico from "@/components/familia/FamiliaHistorico.vue";
import Menor from "@/components/familia/Menor.vue";
import CrearMenorModal from "@/components/modals/CrearMenorModal.vue";
import MenorServicioProgramadoModal from "@/components/modals/MenorServicioProgramadoModal.vue";
import ServiciosColectivosTabla from "@/components/servicios/TablaColectivosFamilias.vue";
import ServiciosTabla from "@/components/servicios/TablaFamilias.vue";
import CurrencyInput from "@/components/widgets/CurrencyInput.vue";
import comarcas from "@/core/data/comarcas";
import { numeroMenoresOpcionesTodas } from "@/core/data/numeroMenores";
import { tipologiasFamiliaTodas } from "@/core/data/tipologiasFamilia";
import tratamientos from "@/core/data/tratamientos";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { ServicioEstado } from "@/enums";
import Page from "@/layout/Page.vue";
import { AuthModule, FamiliasListadoModule } from "@/store/modules";
import { Documento, IAMFile } from "@/types";
import { ArrowDown } from "@element-plus/icons-vue";
import imageCompression from "browser-image-compression";
import Dropzone from "dropzone";
import { ElMessage } from "element-plus";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { ErrorMessage, useField, useForm } from "vee-validate";
import { computed, defineComponent, onBeforeMount, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";
import * as Yup from "yup";

interface Familia {
  tratamiento: string;
  nombre: string;
  apellidos: string;
  dni: string;
  email: string;
  telefono: string;
  direccion: string;
  provincia: string;
  localidad: string;
  comarcaId: string;
  tipologiaFamilia: string;
  numeroMenores: string;
  conIngresos: boolean;
  ingresos: string;
  familiaPrioritaria: boolean;
  documentosEliminar?: string[];
  documentosNuevos?: IAMFile[];
}

export default defineComponent({
  name: "formularios-familia-detalle",
  components: {
    ErrorMessage,
    ServiciosTabla,
    ServiciosColectivosTabla,
    Page,
    ArrowDown,
    Historico,
    Menor,
    CrearMenorModal,
    MenorServicioProgramadoModal,
    CurrencyInput,
  },

  methods: {
    removeDocumento(index) {
      let documentoId = this.documentos[index].id as string;
      if (!this.documentosEliminar) {
        this.documentosEliminar = [];
      }
      this.documentosEliminar.push(documentoId);
      this.documentos.splice(index, 1);
    },

    includeDocumentoNuevo(file) {
      const reader = new FileReader();
      return new Promise<void>((resolve, reject) => {
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.documentosNuevos.push({
            nombre: file.name,
            data: reader.result?.toString() || "",
          });
          resolve();
        };
        reader.onerror = reject;
      });
    },
    deleteDocumentoNuevo(file) {
      this.documentosNuevos = this.documentosNuevos.filter(function (item) {
        return item.nombre !== file.name;
      });
    },
    compressImage(file) {
      const compressionOptions = { maxSizeMB: 0.3 };
      return imageCompression(file, compressionOptions);
    },
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const token = route.params.id;
    const familia = ref();
    const store = useStore();
    const module = getModule(FamiliasListadoModule, store);
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;
    const error = computed(() => module.errors);

    const userComarcaId = authModule.user.comarcaId;
    watch(error, () => {
      if (!error.value) return;

      Swal.fire({
        text:
          t("errors." + (error.value.error.code ?? error.value.error.name)) ??
          error.value.error.name,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t("dialogs.okButton"),
        customClass: {
          confirmButton: "btn btn-primary",
        },
      });
    });

    const cargaDetalle = ref<boolean>(false);
    const guardaDetalle = ref<boolean>(false);
    const estado = ref<string>("");
    const claseEstado = ref<string>("");

    onBeforeMount(async () => {
      cargaDetalle.value = true;
      try {
        await module.fetchById({ comarcaId: comarcaId, id: token as string });
      } catch (err) {
        Swal.fire({
          text: t("formularios.familiaDetalle.cargar.error"),
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: t("dialogs.okButton"),
          customClass: {
            confirmButton: "btn btn-primary",
          },
        });
      } finally {
        cargaDetalle.value = false;
      }
    });

    const datosFamilia = computed(() => module.familia);

    const menoresFamilia = computed(() => {
      return datosFamilia.value?.menores ?? [];
    });

    const menoresServicios = computed(() => {
      return (
        datosFamilia.value?.servicios?.filter(
          (servicio) => servicio.servicioProgramadoId === null
        ) ?? []
      );
    });

    const menoresServiciosColectivos = computed(() => {
      return (
        datosFamilia.value?.servicios?.filter(
          (servicio) => servicio.servicioProgramadoId
        ) ?? []
      );
    });

    const serviciosAprobados = computed(() => {
      return (
        datosFamilia.value?.servicios?.filter(
          (servicio) => servicio.estado === ServicioEstado.aprobado
        ) ?? []
      );
    });

    watch(datosFamilia, () => {
      if (!datosFamilia.value) return;

      comarca.value = datosFamilia.value?.comarca?.nombre ?? "";

      estado.value = datosFamilia.value?.estado.replace("_", " ");
      switch (estado.value.replace(" ", "_")) {
        case "PENDIENTE_DOCUMENTACION":
          claseEstado.value = "badge badge-warning";
          break;
        case "APTO":
          claseEstado.value = "badge badge-success";
          break;
        case "SUSPENDIDO":
          claseEstado.value = "badge badge-danger";
          break;
        default:
          claseEstado.value = "d-none";
          break;
      }

      resetForm({
        values: {
          tratamiento: datosFamilia.value.tratamiento,
          nombre: datosFamilia.value.nombre,
          apellidos: datosFamilia.value.apellidos,
          dni: datosFamilia.value.dni,
          email: datosFamilia.value.email,
          telefono: datosFamilia.value.telefono,
          direccion: datosFamilia.value.direccion,
          provincia: datosFamilia.value.provincia,
          localidad: datosFamilia.value.localidad,
          comarcaId: datosFamilia.value.comarcaId,
          tipologiaFamilia: datosFamilia.value.tipologiaFamilia,
          numeroMenores: datosFamilia.value.numeroMenores,
          conIngresos: datosFamilia.value.conIngresos,
          ingresos: datosFamilia.value.ingresos ?? "",
          familiaPrioritaria: datosFamilia.value.familiaPrioritaria,
          documentosEliminar: datosFamilia.value.documentosEliminar || [],
          documentosNuevos: datosFamilia.value.documentosNuevos || [],
        },
      });
      documentos.value = datosFamilia.value.documentos || [];

      setCurrentPageBreadcrumbs(datosFamilia.value?.dni ?? "", [
        t("menu.planCorresponsables"),
        { title: t("menu.familias"), path: "/listados/familias" },
      ]);
    });

    const schema = Yup.object({
      tratamiento: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.tratamiento.label")),
      nombre: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.nombre.label")),
      apellidos: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.apellidos.label")),
      dni: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.dni.label")),
      email: Yup.string()
        .required()
        .email()
        .matches(
          /^(")?(?:[^."])(?:(?:[.])?(?:[\w\-!#$%&'*+/=?^_`{|}~]))*\1@(\w[-\w]*\.){1,5}([A-Za-z]){2,9}$/,
          t("formularios.familiaDetalle.emailValido.label")
        )
        .label(t("formularios.familiaDetalle.email.label")),
      telefono: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.telefono.label")),
      direccion: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.direccion.label")),
      provincia: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.provincia.label")),
      localidad: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.localidad.label")),
      comarcaId: Yup.string()
        .required()
        .label(t("formularios.familiaDetalle.comarca.label")),
      tipologiaFamilia: Yup.string().required(),
      numeroMenores: Yup.string().required(),
      conIngresos: Yup.boolean().required(),
      ingresos: Yup.string().ensure(),
      familiaPrioritaria: Yup.boolean().required(),
      documentos: Yup.array(),
      documentosEliminar: Yup.array(),
      documentosNuevos: Yup.array(),
    });

    const { resetForm, handleSubmit } = useForm<Familia>({
      validationSchema: schema,
    });

    const { value: tratamiento } = useField("tratamiento");
    const { value: nombre } = useField("nombre");
    const { value: apellidos } = useField("apellidos");
    const { value: dni } = useField("dni");
    const { value: email } = useField("email");
    const { value: telefono } = useField("telefono");
    const { value: direccion } = useField("direccion");
    const { value: provincia } = useField("provincia");
    const { value: localidad } = useField("localidad");
    const { value: comarca } = useField("comarcaId");
    const { value: tipologiaFamilia } = useField("tipologiaFamilia");
    const { value: numeroMenores } = useField("numeroMenores");
    const { value: conIngresos } = useField("conIngresos");
    const { value: ingresos } = useField("ingresos");
    const { value: familiaPrioritaria } = useField("familiaPrioritaria");

    const documentos = ref<Documento[]>([]);
    const { value: documentosEliminar } =
      useField<string[]>("documentosEliminar");
    const { value: documentosNuevos } = useField<IAMFile[]>("documentosNuevos");

    return {
      tipologiasFamiliaTodas,
      numeroMenoresOpcionesTodas,
      tratamientos,
      schema,
      cargaDetalle,
      guardaDetalle,
      familia,
      tratamiento,
      nombre,
      apellidos,
      dni,
      email,
      telefono,
      direccion,
      provincia,
      localidad,
      comarcaId: comarca,
      isAdmin: !userComarcaId,
      comarcas,
      familiaPrioritaria,
      tipologiaFamilia,
      numeroMenores,
      conIngresos,
      ingresos,
      comarca,
      menoresServicios,
      menoresServiciosColectivos,
      serviciosAprobados,
      documentos,
      documentosEliminar,
      documentosNuevos,
      claseEstado,
      estado,
      menoresFamilia,
      eliminar: () => {
        Swal.fire({
          text: t("dialogs.eliminar.confirmacion"),
          icon: "question",
          showCancelButton: true,
          confirmButtonText: t("dialogs.okButton"),
          cancelButtonText: t("dialogs.cancelButton"),
          reverseButtons: true,
        }).then(({ isConfirmed }) => {
          if (!isConfirmed) return;

          cargaDetalle.value = true;
          return module
            .eliminar({
              comarcaId: (comarcaId ?? datosFamilia.value?.comarcaId) as string,
              elementId: token as string,
            })
            .then(() => {
              router.replace("/listados/familias");
              ElMessage({
                message: t("dialogs.eliminar.exito"),
                type: "success",
              });
            })
            .catch((err) => {
              Swal.fire({
                text: t("dialogs.eliminar.error"),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: t("dialogs.okButton"),
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              console.error(err);
            })
            .finally(() => {
              cargaDetalle.value = false;
            });
        });
      },
      onSubmit: handleSubmit(async (values) => {
        guardaDetalle.value = true;
        values.ingresos = values.ingresos.toString();
        try {
          await module
            .update({
              comarcaId: comarcaId,
              id: token as string,
              data: values,
            })
            .then(() => {
              if (!module.errors)
                Swal.fire({
                  text: t("listados.familiaDetalle.guardar.mensaje.exito"),
                  icon: "success",
                  buttonsStyling: false,
                  confirmButtonText: t("dialogs.okButton"),
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.replace("/listados/familias");
                });
            });
        } catch (err) {
          Swal.fire({
            text: t("formularios.profesionalDetalle.guarda.error"),
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t("dialogs.okButton"),
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
        } finally {
          guardaDetalle.value = false;
        }
      }),
    };
  },

  mounted() {
    const dropzoneNuevosDocumentos = new Dropzone(
      "#kt_dropzonejs_nuevos_documentosFamilia",
      {
        url: process.env.VUE_APP_API_URL,
        // The name that will be used to transfer the file
        paramName: "documento",
        maxFilesize: 10, // MB
        addRemoveLinks: true,
        uploadMultiple: true,
        autoProcessQueue: false,
        acceptedFiles: "image/*,application/pdf",
      }
    );
    dropzoneNuevosDocumentos.on("addedfile", async (file) => {
      const fileToAdd = file.type.includes("image/")
        ? await this.compressImage(file)
        : file;

      this.includeDocumentoNuevo(fileToAdd);
    });
    dropzoneNuevosDocumentos.on("removedfile", this.deleteDocumentoNuevo);
  },
});
